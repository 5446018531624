import React from 'react'
// import React, { useState } from "react"
import { Container, Row, Col } from 'react-grid-system'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../components/Layouts/layout'
import SEO from '../../components/seo'
import Button from '../../components/Button/Button'

const MediaInquiriesPage = () => {
  const intl = useIntl()

  return (
    <Layout
      header={
        <span className='bold'>
          <FormattedMessage id='nav.mediaInquiries' />
        </span>
      }
      breadcrumb={{
        text: <FormattedMessage id='nav.news' />,
        path: '/news-and-events/'
      }}
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'nav.mediaInquiries' })}
      />
      <Container>
        <Row>
          <Col>
            <p>
              <FormattedMessage id='mediaInquiries.contentA' />
            </p>
            <Button
              id='generalSubmit'
              linkPath='mailto:media@hcraontario.ca'
              linkExternal={true}
              text='media@hcraontario.ca'
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default MediaInquiriesPage
